import { useMatches } from '@remix-run/react'
import { useMemo } from 'react'

const NODE_ENV =
  typeof process !== 'undefined' ? process.env.NODE_ENV : window.ENV?.NODE_ENV

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param {string} id The route id
 * @returns {JSON|undefined} The router data or undefined if not found
 */
export function useMatchesData(
  id: string
): Record<string, unknown> | undefined {
  const matchingRoutes = useMatches()

  if (NODE_ENV === 'development') {
    // route ids are different in development (without subdomains)
    id = id.replace('__shop', 'shop.$shopSlug')
  }

  const route = useMemo(
    () => matchingRoutes.find((route) => route.id === id),
    [matchingRoutes, id]
  )
  return route?.data as Record<string, unknown>
}
